import { FormattedMessage, useIntl } from 'react-intl';
import { Card, CardBody, CardTitle } from "reactstrap";

const ChartTables = ({ title, data }) => {
	const intl = useIntl()
	const tableHeaders =
		title === "Error Rates"
			? [`${intl.formatMessage({id: "Market Average" })} (%)`, "PPT (%)", `${intl.formatMessage({id: "Difference" })} (%)`]
			: [`${intl.formatMessage({id: "Market Average" })}(ms)`, "PPT (ms)", `${intl.formatMessage({id: "Difference" })} (%)`];

	let titlePostfix = "(ms)";

	if (title === "Error Rates") titlePostfix = "(%)";

	let titleExplanation = "";

	if (title === "Error Rates")
		titleExplanation =
			"Ratio of total number of unsuccessful network requests to total number of network requests";
	else if (title === "Latency P50 Success")
		titleExplanation =
			"50th percentile (median) end to end network latency in milliseconds (TTLB)";
	else if (title === "Latency P90 Success")
		titleExplanation =
			"90th percentile (outlier) end to end network latency in milliseconds (TTLB)";
	else if (title === "TTFB P50 - Success")
		titleExplanation =
			"50th percentile (median) latency from request start to response start in milliseconds; includes connect time";
	else if (title === "TTFB P90 - Success")
		titleExplanation =
			"90th percentile (outlier) latency from request start to response start in milliseconds; includes connect time";

	//Creating a Set of Bucket Names to identify different buckets present.
	const bucketNamesSet = new Set(
		data.otherCompanies.flatMap((company) =>
			company.buckets.map((bucket) => bucket.bucket_name),
		),
	);
	bucketNamesSet.add(
		...data.currentCompany.buckets.map((bucket) => bucket.bucket_name),
	);
	const bucketNames = Array.from(bucketNamesSet);

	const bucketNameMapping = {
		aws: "AWS Metadata",
		"bolt-gcdn": "Google CDN",
		"cf-st": "CloudFront CDN",
		gcp: "Google Metadata",
		fsn: "Fastly CDN",
	};

	//Mapping company data according to distinct bucket names
	const bucketData = (data, bucketSet) => {
		if (data.buckets.find((b) => b.bucket_name === bucketSet)) {
			if (title === "Error Rates")
				return (
					`${Number.parseFloat(
						data.buckets.find((b) => b.bucket_name === bucketSet).error_rate *
							100,
					).toFixed(2)}%`
				);
			if (title === "Latency P50 Success")
				return data.buckets.find((b) => b.bucket_name === bucketSet)
					.latency_p50;
			if (title === "Latency P90 Success")
				return data.buckets.find((b) => b.bucket_name === bucketSet)
					.latency_p90;
			if (title === "TTFB P50 - Success")
				return data.buckets.find((b) => b.bucket_name === bucketSet).ttfb_p50;
			if (title === "TTFB P90 - Success")
				return data.buckets.find((b) => b.bucket_name === bucketSet).ttfb_p90;
			
				return "-";
		}
	};

	//Calculating metrics of the current company differentiated by buckets
	const calculateMetricsForOneBucket = (bucketName) => {
		let sum = 0;
		let count = 0;
		let ppt = 0;
		let difference = 0;
		data.otherCompanies.forEach((company) => {
			company.buckets.find((b) => {
				if (b.bucket_name === bucketName) {
					if (title === "Error Rates") sum += b.error_rate * 100;
					if (title === "Latency P50 Success") sum += b.latency_p50;
					if (title === "Latency P90 Success") sum += b.latency_p90;
					if (title === "TTFB P50 - Success") sum += b.ttfb_p50;
					if (title === "TTFB P90 - Success") sum += b.ttfb_p90;

					count++;
				}
				return b.bucket_name === bucketName;
			});
		});

		const average = sum / count;

		data.currentCompany.buckets.find((bucket) => {
			if (bucket.bucket_name === bucketName) {
				if (title === "Error Rates") {
					ppt = bucket.error_rate * 100 - average;
					difference = `${roundToOneDecimal((ppt / average) * 100)}%`;
				}
				if (title === "Latency P50 Success") {
					ppt = bucket.latency_p50 - average;
					difference = `${roundToOneDecimal((ppt / average) * 100)}%`;
				}
				if (title === "Latency P90 Success") {
					ppt = bucket.latency_p90 - average;
					difference = `${roundToOneDecimal((ppt / average) * 100)}%`;
				}
				if (title === "TTFB P50 - Success") {
					ppt = bucket.ttfb_p50 - average;
					difference = `${roundToOneDecimal((ppt / average) * 100)}%`;
				}
				if (title === "TTFB P90 - Success") {
					ppt = bucket.ttfb_p90 - average;
					difference = `${roundToOneDecimal((ppt / average) * 100)}%`;
				}
			} else {
				ppt = "-";
				difference = "-";
			}
			return bucket.bucket_name === bucketName;
		});
		return {
			average: average,
			ppt,
			difference,
		};
	};

	const roundToOneDecimal = (number) => {
		return Number.parseFloat(number.toFixed(2));
	};

	//This consists of the array of metrics arranged according to the bucket names.
	const metricsOfBuckets = bucketNames.map((bucketName) => {
		return calculateMetricsForOneBucket(bucketName);
	});

	return (
		<div className="d-flex w-100" style={{ gap: "24px" }}>
			<Card
				style={{
					borderRadius: "16px",
					border: "1px solid #CCCCCC",
					width: "65%",
					boxShadow: "4px 4px 0px 0px #CCCCCC",
				}}
			>
				<CardTitle
					style={{
						color: "black",
						fontSize: "20px",
						paddingBottom: "24px",
						fontWeight: "500",
						borderTopLeftRadius: "16px",
						borderTopRightRadius: "16px",
					}}
					className="pt-2 px-1 card-header-bg m-0"
				>
					{intl.formatMessage({id: title })} -{" "}
					<span style={{ fontSize: "14px", fontWeight: 400, opacity: "70%" }}>
						{intl.formatMessage({id: titleExplanation })}
					</span>
				</CardTitle>
				<CardBody className="p-0 w-100">
					<table className="w-100">
						<thead>
							<tr>
								<th
									style={{
										width: "25%",
										paddingLeft: "16px",
										background: "#F2F2F2",
										padding: "12px",
										border: "1px solid #CCCCCC",
										borderLeft: "0",
										fontSize: "14px",
									}}
								>
									Host
								</th>

								<th
									className="px-1"
									style={{
										background: "#F2F2F2",
										padding: "12px",
										border: "1px solid #CCCCCC",
										width: "190px",
										fontSize: "14px",
									}}
								>
									{data.currentCompany.company_name} {titlePostfix}
								</th>

								{data.otherCompanies.map((data) => (
									<th
										key={data.company_id}
										className="px-1"
										style={{
											background: "#F2F2F2",
											padding: "12px",
											border: "1px solid #CCCCCC",
											width: "190px",
											fontSize: "14px",
										}}
									>
										{data.company_name} {titlePostfix}
									</th>
								))}
							</tr>
						</thead>

						<tbody>
							{bucketNames.map((bucketSet, index) => (
								<tr>
									<th
										style={{
											paddingLeft: "16px",
											padding: "12px",
											background: "#F2F2F2",
											border: "1px solid #CCCCCC",
											borderLeft: "0",
											borderBottom: "0",
											fontSize: "14px",
											borderBottomLeftRadius:
												bucketNames.length - 1 === index ? "16px" : "0",
										}}
									>
										{bucketNameMapping[bucketSet.toLowerCase()] || bucketSet}
									</th>
									<td
										style={{
											paddingBottom: "8px",
											borderRight: "1px solid #CCCCCC",
											fontSize: "14px",
											paddingLeft: "16px",
										}}
									>
										{bucketData(data.currentCompany, bucketSet) || "-"}
									</td>
									{data.otherCompanies.map((data, index) => (
										<td
											key={data.company_id}
											style={{
												paddingBottom: "8px",
												borderRight: "1px solid #CCCCCC",
												fontSize: "14px",
												paddingLeft: "16px",
											}}
										>
											{bucketData(data, bucketSet) || "-"}
										</td>
									))}
								</tr>
							))}
						</tbody>
					</table>
				</CardBody>
			</Card>

			<Card
				style={{
					borderRadius: "16px",
					border: "1px solid #CCCCCC",
					width: "35%",
					boxShadow: "4px 4px 0px 0px #CCCCCC",
				}}
			>
				<CardTitle
					style={{
						color: "black",
						fontSize: "20px",
						paddingBottom: "24px",
						fontWeight: "500",
						borderTopLeftRadius: "16px",
						borderTopRightRadius: "16px",
					}}
					className="pt-2 px-1 card-header-bg m-0"
				>
					{data.currentCompany.company_name} vs <FormattedMessage id="Market" />
				</CardTitle>

				<CardBody className="p-0 w-100">
					<table className="w-100">
						<thead>
							<tr>
								{tableHeaders.map((header) => (
									<th
										style={{
											width: "120px",
											paddingLeft: "16px",
											background: "#F2F2F2",
											padding: "12px",
											border: "1px solid #CCCCCC",
											borderLeft: "0",
											fontSize: "14px",
										}}
									>
										{header}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{metricsOfBuckets.map((metric) => (
								<tr>
									<td
										style={{
											padding: "12px",
											paddingBottom: "10.4px",
											borderRight: "1px solid #CCCCCC",
											fontSize: "14px",
										}}
									>
										{metric.average.toFixed(2)}
										{title === "Error Rates" && "%"}
									</td>
									<td
										style={{
											padding: "12px",
											paddingBottom: "10.4px",
											borderRight: "1px solid #CCCCCC",
											fontSize: "14px",
										}}
									>
										{metric.ppt.toFixed(2)}
										{title === "Error Rates" && "%"}
									</td>
									<td
										style={{
											padding: "12px",
											paddingBottom: "10.4px",
											borderRight: "1px solid #CCCCCC",
											fontSize: "14px",
										}}
									>
										{metric.difference}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</CardBody>
			</Card>
		</div>
	);
};

export default ChartTables;
