/* eslint-disable jsx-a11y/role-supports-aria-props */

import { FormattedMessage } from 'react-intl';
import { ReactComponent as ArrowLeft } from "../app-assets/images/icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../app-assets/images/icons/arrow-right.svg";
import "./pagination.css";
import { DOTS, usePagination } from "./usePagination";

export default function PaginationNumbers({
	onPageChange,
	totalCount,
	siblingCount = 3,
	currentPage,
	pageSize,
	className,
}) {
	const paginationRange = usePagination({
		currentPage,
		totalCount,
		siblingCount,
		pageSize,
	});

	if (!paginationRange || currentPage === 0 || paginationRange.length < 2) {
		return null;
	}

	const onNext = () => {
		if (currentPage < Math.ceil(totalCount / pageSize)) {
			onPageChange(currentPage + 1);
		}
	};

	const onPrevious = () => {
		if (currentPage > 1) {
			onPageChange(currentPage - 1);
		}
	};

	return (
		<ul
			style={{
				justifyContent: "space-between",
				flexFlow: "wrap",
				display: "flex",
				padding: "0px",
				marginLeft: "24px",
				marginRight: "24px",
				listStyleType: "none",
			}}
		>
			<li
				aria-disabled={currentPage === 1}
				style={{
					cursor: currentPage === 1 ? "default" : "pointer",
					pointerEvents: currentPage === 1 ? "none" : "all",
					itemsAlign: "center",
					borderRadius: "lg",
					border: "1px",
				}}
				onClick={onPrevious}
			>
				<button
					className="button-arrow d-flex justify-content-center"
					style={{
						color: currentPage === 1 ? "#999999" : "#404040",
						borderColor: currentPage === 1 ? "#b2b2b2" : "#404040",
					}}
				>
					<ArrowLeft
						style={{ color: currentPage === 1 ? "#999999" : "#404040" }}
					/>
					&ensp;
					<FormattedMessage id="Previous"/>
				</button>
			</li>

			<div style={{ display: "flex", gap: "4px", listStyleType: "none" }}>
				{paginationRange.map((pageNumber, index) => (
					<li
						key={index}
						style={{
							pageItem: "item",
							cursor: "pointer",
						}}
					>
						<button
							className="button-number"
							style={{
								background: currentPage === pageNumber ? "black" : "none",
								color: currentPage === pageNumber ? "yellow" : "#737373",
							}}
							onClick={() => onPageChange(pageNumber)}
						>
							{pageNumber === DOTS ? "..." : pageNumber}
						</button>
					</li>
				))}
			</div>

			<li
				disabled={currentPage === Math.ceil(totalCount / pageSize)}
				style={{
					cursor:
						currentPage >= Math.ceil(totalCount / pageSize)
							? "default"
							: "pointer",
					pointerEvents:
						currentPage >= Math.ceil(totalCount / pageSize) ? "none" : "all",
					itemsAlign: "center",
					borderRadius: "lg",
					border: "1px",
				}}
				onClick={onNext}
			>
				<button
					className="button-arrow d-flex justify-content-center"
					style={{
						color:
							currentPage >= Math.ceil(totalCount / pageSize)
								? "#999999"
								: "#404040",
						borderColor:
							currentPage >= Math.ceil(totalCount / pageSize)
								? "#b2b2b2"
								: "#404040",
					}}
				>
					<FormattedMessage id="Next"/>&ensp;
					<ArrowRight
						style={{
							color:
								currentPage >= Math.ceil(totalCount / pageSize)
									? "#999999"
									: "#404040",
						}}
					/>
				</button>
			</li>
		</ul>
	);
}
