import { Bar } from "react-chartjs-2";
import { FormattedMessage } from 'react-intl';
import { Card, CardBody, CardTitle } from "reactstrap";

const BarChart = ({ options, data, className, style }) => {
	return (
		<Card
			className={`${className}`}
			style={{
				borderRadius: "16px",
				boxShadow: "0px 4px 0px 0px #CCCCCC",
				border: "1px solid #CCCCCC",
				...style,
			}}
		>
			<CardTitle
				className="p-2 card-header-bg"
				style={{
					borderBottom: "1px solid #CCCCCC",
					color: "black",
					fontSize: "18px",
					borderTopLeftRadius: "16px",
					borderTopRightRadius: "16px",
				}}
			>
				<FormattedMessage id="Daily Active Users by Age" />
			</CardTitle>
			<CardBody className="py-0">
				<Bar options={options} data={data} />
			</CardBody>
		</Card>
	);
};

export default BarChart;
